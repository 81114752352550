import { Injectable, Inject, Injector } from "@angular/core";
import { ErrorHandlerOptions, SentryErrorHandler } from "@sentry/angular-ivy";
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from './slapfive-global-error-dialog';
/**
 *  This class extends SentryErrorHandler to provide custom error
 *  handling logic.  Mainly, we can filter out certain errors from
 *  being sent to Sentry by checking the error type.  In addtion,
 *  we can take specific types of errors and log them in a Bootstrap
 *  Dialog to be presented to the user.
 *
 *  We are using this technique to surface otherwise challenging
 *  Authentication misconfiguation style errors to the user directly
 *  in the UI to help troubleshooting SSO configurations.
 *
 */
@Injectable({ providedIn: 'root' })
export class SlapFiveGlobalErrorHandler extends SentryErrorHandler {

  private modalService?: BsModalService;

  constructor(private injector: Injector) {
    super({
      showDialog: false,
      logErrors: true
    });
  }

  handleError(error: any): void {
    // Your custom error handling logic here

    // Example: Log the error to the console if it's not a ChunkLoadError
    console.log("SEAN error", error);

    const initialState = {
      error: error
    };

    if (!this.modalService) {
      this.modalService = this.injector.get(BsModalService);
    }
    this.modalService.show(ErrorModalComponent, { initialState });

    // Call Sentry's error handler to capture the error
    super.handleError(error);
  }
}
