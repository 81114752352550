import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 's5-error-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">
        <i class="fa-solid fa-triangle-exclamation"></i>
        Error
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ error?.message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">Close</button>
    </div>
  `
})
export class ErrorModalComponent {
  @Input() error: any;
  constructor(public bsModalRef: BsModalRef) {}
}