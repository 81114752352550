import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';

export interface InfiniteSelectConfig {
  label?: string;
  key?: String;
  itemsPerPage?: Number;
  offset?: Number;
  orderBy?: any;
  value?: any;
  multiple?: boolean;
  entityName?: string;
  bindLabel?: string;
  bindValue?: any;
  useFirst?: boolean;
  placeholder?: string;
  appendTo?: string;
  dropdownPosition?: string;
  inputAttrs?: any;
  variables?: any;
  whereClause?: any;
  addTag?: boolean;
  closeOnSelect?: boolean;
  addTagText?: string;
  clearable?: boolean;
  showTooltip?: boolean;
  templates?: {[key: string]: TemplateRef<any>};
}

export interface ListQueryResults {
  ['String']: Object[];
}

@Component({
  selector: 's5-infinite-select',
  templateUrl: './infinite-select.component.html',
  styleUrls: ['./infinite-select.component.scss']
})
export class InfiniteSelectComponent implements OnInit, OnDestroy {

  @Input() public config: InfiniteSelectConfig;
  @Input() public query;
  @Input() public items: any[] = [];
  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

  subscription$: Subscription = new Subscription();
  loaded = false;
  selected:any;
  searchVal;
  dynamicWhereClause;
  currentSortClause;
  ngSelectDisplay = true;

  pageVars: any = {
    itemsPerPage: 25,
    offset: 0,
  }

  constructor( ) { }

  ngOnInit(): void {
    this.fireQuery();
  }

  ngOnDestroy(): void {
      this.subscription$.unsubscribe();
  }

  fireQuery() {
    const queryVars: any = this.config.variables || {};
    const docString = this.query.document.loc.source.body;
    if (docString.indexOf('$limit') > -1) {
      queryVars.limit = this.config.itemsPerPage || this.pageVars.itemsPerPage;
    }
    if (docString.indexOf('$offset') > -1) {
      queryVars.offset = this.config.offset || this.pageVars.offset;
    }
    if (docString.indexOf('$orderBy') > -1) {
      queryVars.orderBy = this.config.orderBy;
    }
    if (docString.indexOf('$searchVal') > -1) {
      queryVars.searchVal = `%${this.searchVal || ""}%`;
    }
    if (docString.indexOf('$dynamicWhereClause') > -1) {
      queryVars.dynamicWhereClause = {};
      if (this.config.whereClause) {
        queryVars.dynamicWhereClause = this.config.whereClause;
        if (docString.indexOf('$searchVal') === -1) {
          if (queryVars.dynamicWhereClause[this.config.bindLabel] && this.searchVal) {
            queryVars.dynamicWhereClause[this.config.bindLabel]._ilike = `%${this.searchVal || ""}%`;
          } else if (this.searchVal) {
            queryVars.dynamicWhereClause[this.config.bindLabel] = {_ilike: `%${this.searchVal || ""}%`};
          }
        }
      } else if (this.searchVal) {
        queryVars.dynamicWhereClause = {[this.config.bindLabel]: {_ilike: `%${this.searchVal || ""}%`}};
      }
    }

    this.subscription$.add(this.query.watch(queryVars, {fetchPolicy: 'cache-and-network'}).valueChanges.subscribe(v =>  {
      let responseData = JSON.parse(JSON.stringify(v.data));
      if (responseData.client) {
        responseData = responseData.client[0];
      }
      let newValues = responseData[this.config.entityName];
      this.items = _.uniqBy(this.items.concat(newValues), this.config.key);

      if (this.config.useFirst && !this.config.value && this.items[0]) {
        this.config.value = this.items[0];
        this.valChanged(null);
      }
      this.loaded = true;
    }, (error) => {console.log('error', error)}));
  }

  valChanged($event) {
    this.valueChange.emit(this.config.value);
    if (this.config.variables) {
      this.config.variables.dynamicWhereClause && delete this.config.variables.dynamicWhereClause;
      this.config.variables.searchVal && delete this.config.variables.searchVal;
    }
    this.searchVal = null;
    this.fireQuery();
  }

  fetchMore() {
    const len = this.items.length;
    if (len > this.pageVars.offset) {
      this.pageVars.offset += this.pageVars.itemsPerPage;
      this.fireQuery();
    }
  }

  onSearch(searchVal) {
    this.items = [];
    this.pageVars.offset = 0;
    this.searchVal = searchVal.term;
    this.fireQuery();
  }

  scroll(ev) {
    this.ngSelectDisplay = false;
    setTimeout(() => {
      this.ngSelectDisplay = true;
    }, 100);
  }



}
