import { Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild } from '@angular/core';
import { Utils } from '../../shared/utils/Utils';

// Code adapted from ng2-typeahead

interface Suggestion {
  value: string;
  text: string;
}
@Component({
  selector: 'single-select',
  styleUrls: ['./single-select.component.scss'],
  templateUrl: './single-select.template.html',
})
export class SingleSelectComponent implements OnInit, OnChanges {

  @Input() public value: string;
  @Output() private valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() private otherValues: any[];
  @Input() private otherValuesKey: string;
  @Input() private otherValuesAreCommaSeparated = false;
  @Input() placeholder = '';
  @Input() clearIcon = false;
  @ViewChild('ssInput', { static: false }) private ssInput;


  public activeSuggestion: Suggestion;

  public suggestions: Suggestion[];
  public suggestionsVisible = false;

  constructor() {
  }

  public ngOnInit() {
    this.setValues();
  }

  public ngOnChanges() {
    this.setValues();
  }

  public changed() {
    this.valueChange.emit(this.value);
  }

  private setValues() {
    // const start = performance.now();
    this.suggestions = [];
    if (!this.otherValues) {
      Utils.logError('single-select ' + this.otherValuesKey + ' missing [otherValues]');
      return;
    }
    if (!this.otherValuesKey) {
      Utils.logError('single-select missing [otherValuesKey]');
      return;
    }
    for (const otherValue of this.otherValues) {
      const vals = this.getVals(otherValue, this.otherValuesKey);
      for (const val of vals) {
        let found = false;
        this.suggestions.forEach(({ value: val2 }) => {
          if (val === val2) {
            found = true;
          }
        });
        if (val && val.length > 0 && !found && val !== this.value) {
          // Show count of filter suggestions
          // TODO: PERF REFACTOR - this appears to be very slow
          // const count = this.getCount(val, this.otherValues, this.otherValuesKey)
          // this.suggestions.push({ text: val + " (" + count + ")", value: val });
          this.suggestions.push({ text: val, value: val });
        }
      }
    }
    Utils.sortAlphaNumeric(this.suggestions)
  }

  public getCount(val, otherValues, otherValuesKey) {
    // const start = performance.now();
    let count = 0;
    for (const otherValue of otherValues) {
      const vals = this.getVals(otherValue, otherValuesKey);
      for (const val2 of vals) {
        if (val === val2) {
          count++;
        }
      }
    }
    return count;
  }



  public getVal(item, props) {
    let i = item;
    for (const k of props.split('.')) {
      if (i) {
        i = i[k];
      }
    }
    return i;
  }

  public getVals(item, props) {
    let i = item;
    let p = props;
    while (p && p.trim().length > 0 && i) {
      const split = p.split(/\.(.+)/);
      let thisKey = split[0];
      const nextKeys = split[1];
      if (thisKey.endsWith('[]')) {
        thisKey = thisKey.slice(0, -2);
        let ret = [];
        for (const subItem of i[thisKey]) {
          ret = ret.concat(this.getVals(subItem, nextKeys));
        }
        return ret;
      } else {
        i = i[thisKey];
      }
      p = nextKeys;
    }
    if (i && this.otherValuesAreCommaSeparated) {
      return i.split(',');
    } else {
      return new Array().concat([i]);
    }
  }

  suggestionMouseDown(suggestion: string) {
    this.value = suggestion;
    this.setValues();
    this.changed();
    setTimeout(() => { this.ssInput.nativeElement.focus(); });
  }

  clear() {
    this.value = '';
    this.setValues();
    this.changed();
  }
}
